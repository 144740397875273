import React from "react";
import Menu from "../../Menu/Menu";
import "./kontakt.css";

const Kontakt = () => {
	return (
		<>
			<Menu />
			<div className="kontakt">
				<div>tel. 579 021 512</div>
				<div>
					<a href="mailto:biuro@adibau.pl">email: biuro@adibau.pl</a>
				</div>
				<div>Rejon prac w obrębie miast: </div>
				<div>Wodzisław Śląski, Pszów ...</div>
			</div>
		</>
	);
};
export default Kontakt;
