import React, { useEffect, useRef, useState } from "react";

// import dane from "./zdjecia.json";
import "./galeria.css";
import Menu from "../Menu/Menu";

interface data {
	id: number;
	link: string;
	lazienki: boolean;
	podlogi: boolean;
	schody: boolean;
	plytyGK: boolean;
}
function Galeria() {
	const myImg = useRef<HTMLDivElement[]>([]);
	const zamknijPodglad = useRef<HTMLDivElement | null>(null);
	const buttonsRef = useRef<HTMLButtonElement[]>([]);
	const [sortedData, setSortedData] = useState<data[]>([]);
	const [orginData, setOrginData] = useState<data[]>([]);

	useEffect(() => {
		fetch("./galeria/zdjecia.json")
			.then((response) => response.json())
			.then((dane: data[]) => {
				setOrginData(dane);
			})
			.catch((e) => {
				console.log("error from zdjecia.json ", e);
				const dane = require("./zdjecia.json");
				setOrginData(dane);
			});
	}, []);
	useEffect(() => {
		buttonClick(0, "wszystko");
	}, [orginData]);

	const listki = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
	const removeListki = (a: any) => {
		myImg.current[a].classList.remove("spiner_show");
		myImg.current[a].classList.add("spiner_off");
	};

	const buttonClick = (e: number, titel: string) => {
		buttonsRef.current[e].classList.add("active");
		for (let i = 0; i < buttonsRef.current.length; i++) {
			if (i !== e) {
				buttonsRef.current[i].classList.remove("active");
			}
		}
		let newData: data[] = [];
		if (titel === "wszystko") {
			newData = orginData;
		}
		if (titel === "lazienki") {
			newData = orginData.filter((data) => {
				return true === data.lazienki;
			});
		}
		if (titel === "podlogi") {
			newData = orginData.filter((data) => {
				return true === data.podlogi;
			});
		}
		if (titel === "schody") {
			newData = orginData.filter((data) => {
				return true === data.schody;
			});
		}
		if (titel === "plytyGK") {
			newData = orginData.filter((data) => {
				return true === data.plytyGK;
			});
		}

		setSortedData(newData);
	};

	const podglad = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.currentTarget.classList.toggle("klik");
		zamknijPodglad.current?.classList.toggle("zamknij_podglad_klik");
	};
	const showSettings = (event: any) => {
		event.preventDefault();
	};
	return (
		<>
			<Menu />
			<div className="galeriaAll">
				<div className="sortowanie">
					<button
						className="active wszystko"
						ref={(e: HTMLButtonElement) => {
							buttonsRef.current[0] = e;
						}}
						onClick={() => buttonClick(0, "wszystko")}
					>
						Wszystko
					</button>
					<button
						className="lazienki"
						ref={(e: HTMLButtonElement) => {
							buttonsRef.current[1] = e;
						}}
						onClick={() => buttonClick(1, "lazienki")}
					>
						Łazienki
					</button>
					<button
						className="podlogi"
						ref={(e: HTMLButtonElement) => {
							buttonsRef.current[2] = e;
						}}
						onClick={() => buttonClick(2, "podlogi")}
					>
						Podłogi
					</button>
					<button
						className="schody"
						ref={(e: HTMLButtonElement) => {
							buttonsRef.current[3] = e;
						}}
						onClick={() => buttonClick(3, "schody")}
					>
						Schody
					</button>
					<button
						className="GK"
						ref={(e: HTMLButtonElement) => {
							buttonsRef.current[4] = e;
						}}
						onClick={() => buttonClick(4, "plytyGK")}
					>
						Płyty GK
					</button>
				</div>
				<div className="zamknij_podglad" ref={zamknijPodglad} onClick={podglad}>
					---- ZAMKNIJ -----
				</div>

				<div className="wyswietlacz">
					{sortedData
						.map((e, index) => {
							return (
								<div key={e.id} className="nic" onClick={(e) => podglad(e)}>
									<img className="img" src={e.link} alt="" onLoad={(e) => removeListki(index)} loading="lazy" />
									<div className="divContainer spiner_show" ref={(e: HTMLDivElement) => (myImg.current[index] = e)}>
										{listki.map((e, index) => {
											return <div className={`listki listek${e}`} key={index}></div>;
										})}
									</div>
								</div>
							);
						})
						.reverse()}
				</div>
			</div>
		</>
	);
}

export default Galeria;
