import React, { useState } from "react";
import "./kalkulator.css";
const Kalkulator = () => {
	return (
		<>
			<iframe className="kalkulator" src="https://adibau.pl/kalkulator/kalkulator1.html" title="kalkulator"></iframe>
		</>
	);
};
export default Kalkulator;
