import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Latajacy from "./widgets/latajacy/latajacy";
import Home from "./widgets/Home/Home";
import Stopka from "./widgets/stopka/Stopka";
import Galeria from "./widgets/Galeria/Galeria";
import Kontakt from "./widgets/Home/Kontakt/Kontakt";

function App() {
	return (
		<>
			<Latajacy />

			<BrowserRouter>
				<Routes>
					<Route path={"/"} element={<Home />} />

					<Route path={"/galeria"} element={<Galeria />} />
					<Route path={"/kontakt"} element={<Kontakt />} />
					<Route path={"*"} element={<Home />} />
				</Routes>
			</BrowserRouter>
			<Stopka />
		</>
	);
}

export default App;

