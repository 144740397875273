import React, { useState } from "react";
import "./stopka.css";
import Kalkulator from "../Kalkulator/Kalkulator";

function Stopka() {
	const [showKalkulator, setShowKalkulator] = useState(false);

	function stopkaClick(): void {
		setShowKalkulator(!showKalkulator);
	}

	return (
		<>
			{showKalkulator && <Kalkulator />}
			<div className="stopka" onClick={stopkaClick}>
				<p>© strona w ciągłej rozbudowie </p>
				<p className="admin"> aktualizacja na dzień - 28.05.2022 ... Administrator ....</p>
				<p className="liczbaOdwiedzin">
					<span className="spanliczbaOdwiedzin"></span>
				</p>
			</div>
		</>
	);
}

export default Stopka;
