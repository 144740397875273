import React, { useState } from "react";
import menuIcon from "../images/menu.webp";
import menuIconClose from "../images/burger close.png";
import "./menu.css";
import { NavLink } from "react-router-dom";

const Menu = () => {
	const [icon, setIcon] = useState(menuIcon);
	const [iconClick, setIconClick] = useState(false);
	const menuIconClick = () => {
		if (icon === menuIcon) {
			setIcon(menuIconClose);
			setIconClick(true);
		} else {
			setIcon(menuIcon);
			setIconClick(false);
		}
	};
	return (
		<>
			<div className="menuIconContainer" onClick={menuIconClick}>
				<img src={icon} alt="menu burger" className="menuIcon" />
				{iconClick && (
					<div>
						<NavLink className="notActive home" to={"/"}>
							Home
						</NavLink>
					</div>
				)}
			</div>
		</>
	);
};
export default Menu;
