import React from "react";
import "./latajacy.css";
import logo from "../images/logo2.png";

function Latajacy() {
	return (
		<>
			{/* <h1> Glazurnik - Remonty łazienek - Pszów , Wodzisław Śląski</h1>  */}
			<div className="latajacy">
				<img src={logo} alt="logo ADIBAU" />
			</div>
		</>
	);
}

export default Latajacy;
