import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./home.css";

function Home() {
	const [images, setImages] = useState<number[]>([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);

	useEffect(() => {
		const interval = setInterval(async () => {
			if (images[0] === 13) {
				setImages([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
			} else if (images[0] === 1) {
				setImages([13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]);
			}
		}, 4000);
		return () => clearInterval(interval);
	}, [images]);
	return (
		<>
			<div className="okno_glowne">
				<div className="ramka_zdjec">
					{images.map((e, index) => {
						return (
							<div key={e}>
								<img src={require(`../images/r${images[Number(index)]}.jpg`)} aria-hidden alt="image" loading="lazy" />
							</div>
						);
					})}
				</div>

				<div className="przywitanie">
					<p>witaj</p>
					<h1>jestem adam pytlik</h1>
					<h2>glazurnik i wykończeniowiec</h2>
					<p></p>
					<div className="buttony">
						<NavLink className="notActive kontaktButon" to={"/kontakt"}>
							Kontakt
						</NavLink>
						<NavLink className="notActive galeriaButon" to={"/galeria"}>
							Galeria
						</NavLink>
						<NavLink className="notActive zakresButon" to={"/zakres-prac"}>
							Zakres Prac
						</NavLink>
					</div>
				</div>
			</div>
		</>
	);
}

export default Home;
